import React from 'react';

import { ContainerTwoColumn, ContainerButtons, H2 } from '_templates/Sections';
import CoffeeShopIllustration from '_images/svg/illustrations/CoffeeShop.svg';
import colours from '_theming/colours';
import InternalLinkButton from '_components/InternalLinkButton';

import { ContainerGraphic } from './common/Section';

/**
 * The "Accept Payments For Less" section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const AcceptPaymentsForLess = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphic>
      <CoffeeShopIllustration />
    </ContainerGraphic>

    <div>
      <H2 textAlign="left">Accept payments for less</H2>

      <p>
        We work hard to bring your business the tools to help your business grow, and keep more of
        the money that your hard work earned.
      </p>

      <ContainerButtons>
        <InternalLinkButton to="/payments/" bgcolor={colours.redDamask}>
          Learn about Payments
        </InternalLinkButton>
      </ContainerButtons>
    </div>
  </ContainerTwoColumn>
);

export default AcceptPaymentsForLess;
