import React from 'react';

import { ContainerTwoColumn, ContainerButtons, H2 } from '_templates/Sections';
import colours from '_theming/colours';
import InternalLinkButton from '_components/InternalLinkButton';
import PharmacyIllustration from '_images/svg/illustrations/Pharmacy.svg';

import { ContainerGraphic } from './common/Section';

/**
 * The "Make Every Transaction Count For More" section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const MakeEveryTransactionCountForMore = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphic>
      <PharmacyIllustration />
    </ContainerGraphic>

    <div>
      <H2 textAlign="left">Make every transaction count for more</H2>

      <p>
        From terminals to gift &amp; loyalty card programs, eCommerce solutions to integrated
        payments, we have all the tools to help your business grow and thrive.
      </p>

      <ContainerButtons>
        <InternalLinkButton to="/services/" bgcolor={colours.redDamask}>
          Learn about Merchant Services
        </InternalLinkButton>
      </ContainerButtons>
    </div>
  </ContainerTwoColumn>
);

export default MakeEveryTransactionCountForMore;
