import React from 'react';
import { Helmet } from 'react-helmet';

import {
  ContainerPage,
  Margin,
  ContainerNavigation,
  ContainerSection,
  ContainerLandingSection,
  ContainerStartGettingPaid,
  ContainerSectionDivider,
  ContainerFooterDivider,
  ContainerFooter,
} from '_templates/Pages';
import AcceptPaymentsForLess from '_sections/Home/AcceptPaymentsForLess';
import BuiltForDevelopers from '_sections/Home/BuiltForDevelopers';
import colours from '_theming/colours';
import Curved from '_components/Curved';
import Footer from '_sections/Footer';
import ItPaysToShopAround from '_sections/Home/ItPaysToShopAround';
import KeepMoreOfTheMoneyYouMake from '_sections/Home/KeepMoreOfTheMoneyYouMake';
import Layout from '_components/Layout';
import MakeEveryTransactionCountForMore from '_sections/Home/MakeEveryTransactionCountForMore';
import NavigationBar from '_components/NavigationBar';
import ReadyToStartGettingPaid from '_sections/Home/ReadyToStartGettingPaid';
import useSiteMetadata from '_hooks/useSiteMetadata';

/**
 * Index page.
 *
 * @param {object} props Component props.
 * @param {object} props.location Router Location object.
 * @returns {React.Component} Index page.
 */
const IndexPage = ({ location }) => {
  const { siteUrl } = useSiteMetadata();

  const pageUrl = `${siteUrl}${location.pathname}`;
  const title = 'Lowest Processing and Merchant Account Fees';
  const description =
    'We help businesses analyze applicable processor options to figure out the best choice based on their industry, technology and volume of sales.';
  const imageUrl = `${siteUrl}/images/Homepage.png`;
  const imageAltText = 'People participating in digital and physical commerce';
  const jsonLD = JSON.stringify(
    {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: 'Pricematepay',
      legalName: 'Pricematepay Inc',
      url: pageUrl,
      logo: `${siteUrl}/images/logos/Pricematepay/LightBlue.png`,
      foundingDate: '2019',
      founders: [
        {
          '@type': 'Person',
          name: 'Bartosz Bobnis',
        },
        {
          '@type': 'Person',
          name: 'Michael Smith',
        },
      ],
      address: {
        '@type': 'PostalAddress',
        streetAddress: '1200 Bay Street Suite 202',
        addressLocality: 'Toronto',
        addressRegion: 'ON',
        postalCode: 'M5R2A5',
        addressCountry: 'CA',
      },
      contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'Sales',
        telephone: '[+1-647-494-3992]',
        email: 'sales@pricematepay.com',
        areaServed: ['CA', 'US'],
        availableLanguage: 'EN',
      },
      sameAs: [
        'https://www.instagram.com/pricematepay',
        'https://www.linkedin.com/company/15830308',
      ],
    },
    null,
    2
  );

  return (
    <Layout>
      <Helmet defer={false}>
        <title>{title} - Pricematepay</title>
        <link rel="canonical" href={pageUrl} />
        <meta
          name="keywords"
          content="credit card processing, merchant services, payment gateway, virtual terminal, subscription billing, e-commerce, retail, point of sale hardware"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta name="og:image:width" content="1200" />
        <meta name="og:image:height" content="630" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image:alt" content={imageAltText} />
        <meta name="twitter:image" content={imageUrl} />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="630" />
        <meta name="description" content={description} />
        <script type="application/ld+json">{jsonLD}</script>
      </Helmet>

      <ContainerPage>
        <ContainerNavigation>
          <Margin>
            <NavigationBar />
          </Margin>
        </ContainerNavigation>

        <ContainerLandingSection bgColor={colours.daintree}>
          <Margin>
            <ItPaysToShopAround />
          </Margin>
        </ContainerLandingSection>

        <ContainerSectionDivider>
          <Curved preserveAspectRatio="none" fillColor={colours.daintree} />
        </ContainerSectionDivider>

        <ContainerSection bgColor={colours.white} disableTopPadding>
          <Margin>
            <AcceptPaymentsForLess flexDirection="row-reverse" />
          </Margin>
        </ContainerSection>

        <ContainerSection bgColor={colours.linkWaterLight}>
          <Margin>
            <KeepMoreOfTheMoneyYouMake />
          </Margin>
        </ContainerSection>

        <ContainerSection bgColor={colours.white}>
          <Margin>
            <MakeEveryTransactionCountForMore flexDirection="row-reverse" />
          </Margin>
        </ContainerSection>

        <ContainerSection bgColor={colours.linkWaterLight}>
          <Margin>
            <BuiltForDevelopers />
          </Margin>
        </ContainerSection>

        <ContainerStartGettingPaid bgColor={colours.white}>
          <Margin>
            <ReadyToStartGettingPaid />
          </Margin>
        </ContainerStartGettingPaid>

        <ContainerFooterDivider>
          <Curved preserveAspectRatio="none" fillColor={colours.daintree} />
        </ContainerFooterDivider>

        <ContainerFooter bgColor={colours.daintree}>
          <Margin>
            <Footer />
          </Margin>
        </ContainerFooter>
      </ContainerPage>
    </Layout>
  );
};

export default IndexPage;
