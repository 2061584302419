import React from 'react';

import { ContainerTwoColumn, ContainerButtons, H2 } from '_templates/Sections';
import colours from '_theming/colours';
import EcommerceIllustration from '_images/svg/illustrations/Ecommerce.svg';
import InternalLinkButton from '_components/InternalLinkButton';

import { ContainerGraphic } from './common/Section';

/**
 * The "Keep More of The Money You Make" section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const KeepMoreOfTheMoneyYouMake = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphic>
      <EcommerceIllustration />
    </ContainerGraphic>

    <div>
      <H2 textAlign="left">Keep more of the money you make</H2>

      <p>
        With powerful tools like hosted invoices and recurring subscriptions, you can spend less
        time focused on billing and more on the things that matter — growing your business.
      </p>

      <ContainerButtons>
        <InternalLinkButton to="/billing/" bgcolor={colours.redDamask}>
          Learn about Billing
        </InternalLinkButton>
      </ContainerButtons>
    </div>
  </ContainerTwoColumn>
);

export default KeepMoreOfTheMoneyYouMake;
