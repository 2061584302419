import React from 'react';

import {
  ContainerTwoColumn,
  ContainerGraphic,
  ContainerText,
  H1,
  ContainerButtons,
} from '_templates/LandingSection';
import colours from '_theming/colours';
import ExternalLinkButton from '_components/ExternalLinkButton';
import HomepageIllustration from '_images/svg/illustrations/Homepage.svg';
import InternalLinkButton from '_components/InternalLinkButton';

/**
 * The "It Pays To Shop Around" section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const ItPaysToShopAround = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphic>
      <HomepageIllustration />
    </ContainerGraphic>

    <ContainerText>
      <H1>It pays to shop around</H1>

      <p>
        Pricematepay helps businesses compare and select the right payment processor for their
        merchant services, so they get the pricing they deserve.
      </p>

      <p>
        We build a personal profile for your business and analyze applicable processor options,
        putting them side-by-side to figure out the best choice based on your industry, technology,
        volume of sales, and other customized considerations.
      </p>

      <p>It’s a big decision and it pays to know your options.</p>

      <ContainerButtons>
        <ExternalLinkButton
          href="https://gateway.pricematepay.com/signup"
          bgcolor={colours.redDamask}
          target="_blank"
        >
          Get Started Now
        </ExternalLinkButton>

        <InternalLinkButton to="/contact/" bgcolor={colours.linkWater}>
          Get a Quote
        </InternalLinkButton>
      </ContainerButtons>
    </ContainerText>
  </ContainerTwoColumn>
);

export default ItPaysToShopAround;
