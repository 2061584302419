import React from 'react';

import { ContainerTwoColumn, ContainerButtons, H2 } from '_templates/Sections';
import colours from '_theming/colours';
import DevelopersIllustration from '_images/svg/illustrations/Developers.svg';
import ExternalLinkButton from '_components/ExternalLinkButton';

import { ContainerGraphic } from './common/Section';

/**
 * The "Built For Developers" section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const BuiltForDevelopers = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphic>
      <DevelopersIllustration />
    </ContainerGraphic>

    <div>
      <H2 textAlign="left">Built for developers</H2>

      <p>
        Pricematepay Gateway API is a simple and secure way to add payments functionality to your
        website or software without worrying about compliance.
      </p>

      <ContainerButtons>
        <ExternalLinkButton
          href="https://gateway.pricematepay.com/docs"
          bgcolor={colours.redDamask}
          target="_blank"
        >
          Read Documentation
        </ExternalLinkButton>
      </ContainerButtons>
    </div>
  </ContainerTwoColumn>
);

export default BuiltForDevelopers;
